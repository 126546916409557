import { Fade } from 'react-awesome-reveal'
import logo from '../../assets/logos/logo2.png'

export function Footer() {
    const handleClickScroll = (elementId:string) => {
        const element = document.getElementById(elementId);
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth', block: "center"});
        }
    };
  return (
    <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
        <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
            <div className="sm:flex sm:items-center sm:justify-between">
                <a onClick={() => handleClickScroll('content-caroursel')} className="flex items-center mb-4 sm:mb-0 cursor-pointer ">
                    <Fade triggerOnce>
                        <img src={logo} className="h-16 mr-3" alt="Logo Hurba" />
                    </Fade>
                </a>
                <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                    <li>
                        <a className="mr-4 hover:underline md:mr-6 cursor-pointer "onClick={() => handleClickScroll('introduction')}>Quem somos</a>
                    </li>
                    <li>
                        <a className="mr-4 hover:underline md:mr-6 cursor-pointer "onClick={() => handleClickScroll('services')}>Soluções</a>
                    </li>
                    <li>
                        <a className="mr-4 hover:underline md:mr-6 cursor-pointer "onClick={() => handleClickScroll('contact')}>Orçamento</a>
                    </li>
                    <li>
                        <a className="hover:underline cursor-pointer md:mr-6" onClick={() => handleClickScroll('contract')}>Vagas</a>
                    </li>
                    <li>
                    <a href="tel:+5511992536640" className="hover:underline cursor-pointer " >(11) 99253-6640</a>
            </li>
                </ul>
            </div>
            <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
            <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 Hurba™. Todos os direitos reservados.</span>
        </div>
    </footer>
  )
}