import React from 'react';
import './styles.css';
import HeaderNav from '../navbar';
import { Footer } from '../footer';
import { DefaultSpeedDial } from '../speedDial';


const Template: React.FC<any> = (props) => {

    return (
        <>

            <HeaderNav />
			<DefaultSpeedDial/>

            <section className={props.className ? ' ' + props.className : ''}>
                {/* {!mobile() && <Menu />} */}

                <main className={props.classMain ? props.classMain : ''}>
                    { props.children }
                </main>
            </section>

            <Footer/>

            {/* {mobile() && <MenuMobile />} */}
        </>
    );
}

export default Template;