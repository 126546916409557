import React from "react";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import './styles.css'

import engineer from '../../assets/images/local-de-construcao.jpg'
import concierge from '../../assets/images/porteiro-zip.jpg'
import maintenance from '../../assets/images/eletricista-zip.jpg'

const CarouselNavigation: React.FC = () => {
    const handleClickScroll = (elementId:string) => {
        const element = document.getElementById(elementId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: "center"});
        }
    };
    return <div id='content-caroursel' className="grid min-h-[140px] w-full place-items-center  scroll-mt-64 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-6">

        <Carousel loop={true} autoplay={true} autoplayDelay={9000}>

            <div className="relative h-full w-full">
                <img
                src={engineer}
                alt="Engenheiro Civil"
                className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full items-center bg-black/50">
                    <div className="w-4/5 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                        <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                        Transformando ideias em realidade
                        </Typography>
                        <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                        >
                        Descubra como nossos serviços de construção civil podem tornar seus projetos de obras e reformas
                        uma realidade. Conte com nossa experiência para garantir qualidade, economia e prazos cumpridos
                        em cada empreendimento.
                        </Typography>
                        <div className="flex gap-2">
                            <Button size="lg" color="white" onClick={() => handleClickScroll('services')}>
                                Explore
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                src={concierge}
                alt="Portaria"
                className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full items-center bg-black/50">
                    <div className="w-4/5 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                        <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                        Sua segurança, nossa prioridade
                        </Typography>
                        <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                        >
                        Confie em nossa equipe de portaria para garantir a proteção
                        do seu espaço. Profissionais treinados e vigilância 24 horas,
                        oferecendo tranquilidade e controle de acesso. Estamos comprometidos
                        com sua segurança.
                        </Typography>
                        <div className="flex gap-2">
                            <Button size="lg" color="white" onClick={() => handleClickScroll('services')}>
                                Explore
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative h-full w-full">
                <img
                src={maintenance}
                alt="Marcenaria"
                className="h-full w-full object-cover"
                />
                <div className="absolute inset-0 grid h-full w-full items-center bg-black/50">
                    <div className="w-5/6 pl-12 md:w-2/4 md:pl-20 lg:pl-32">
                        <Typography
                        variant="h1"
                        color="white"
                        className="mb-4 text-3xl md:text-4xl lg:text-5xl"
                        >
                        Terceirização de manutencistas, limpeza e portaria
                        </Typography>
                        <Typography
                        variant="lead"
                        color="white"
                        className="mb-12 opacity-80"
                        >
                        Conte com nossos especialistas para cuidar do seu espaço. Com anos de experiência, oferecemos
                        soluções abrangentes que vão desde equipes fixas a equipes temporárias para manter seu ambiente
                        impecável e seguro. Descubra como podemos simplificar sua vida.
                        </Typography>
                        <div className="flex gap-2">
                            <Button size="lg" color="white" onClick={() => handleClickScroll('services')}>
                                Explore
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </Carousel>
    </div>
}

export default CarouselNavigation;