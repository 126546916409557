import { useState } from 'react';
import './styles.css';
import logo from '../../assets/logos/logo2.png'
import logoWhite from '../../assets/logos/logo-white2.png'
import { AiFillCaretDown } from 'react-icons/ai';


const HeaderNav = () => {
    const [menu, setMenu] = useState<boolean>(false);
    const style  = 'cursor-pointer block text-lg text-gray-900 hover:text-black border border-transparent hover:border-purple-900 hover:text-purple-900 rounded-full '
    const handleClickScroll = (elementId:string) => {
      const element = document.getElementById(elementId);
      if (element) {
        if(menu) setMenu(!menu)
        element.scrollIntoView({ behavior: 'smooth', block: "center"});
      }
    };

    return (
        <>
            <nav className="menu">
                <div className="container-menu">
                    <a onClick={() => handleClickScroll('content-caroursel')} className="logo cursor-pointer">
                        <img src={ menu ? logoWhite : logo} alt="Hurba"/>
                    </a>
                    <div className="menu_toggle" onClick={() => {setMenu(!menu);}}>
                        <div className="one"    style={menu?{transform: 'rotate(45deg) translate(7px, 7px)'}:{}}></div>
                        <div className="two"    style={menu?{opacity: '0'}:{}}></div>
                        <div className="three"  style={menu?{transform: 'rotate(-45deg) translate(8px, -9px)'}:{}}></div>
                    </div>
                    <div className={`links ${menu? 'on':''}`}>

                      <a className={style}  onClick={() => handleClickScroll('introduction')}><span>Quem somos</span></a>
                      <a className={style} onClick={() => handleClickScroll('services')}>Soluções</a>
                      <a className={style} onClick={() => handleClickScroll('contact')}>Orçamento</a>
                      <a className={style} onClick={() => handleClickScroll('contract')}>Vagas</a>
                      <a href="tel:+5511992536640" className={style}>(11) 99253-6640</a>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default HeaderNav;
