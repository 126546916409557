import React from 'react';
import { BiMap } from "react-icons/bi";
import './styles.css';
import { Fade } from 'react-awesome-reveal';

// Urls
const map = "https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d457.1035691442484!2d-46.64316750715549!3d-23.574600277625446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1srua%20desembargador%20eliseu%20guilherme%20315%20para%C3%ADso%20sp%20iframe!5e0!3m2!1spt-BR!2sbr!4v1695424089632!5m2!1spt-BR!2sbr";

const Map: React.FC = () => {
    return <section id='map'>
        <iframe
            title='mapa'
            src={map}
            style={{border:'0', width:'100vw', margin:'0px'}}
            loading="lazy">
        </iframe>

        <div className='infos'>
            <div className="header">
                <Fade direction='up' triggerOnce cascade>
                    <BiMap style={{marginRight:'10px', fontSize:'1.7em'}}/>
                    <h1>Nossa localização</h1>
                </Fade>
            </div>

            <div className="body">
                <p>Rua desembargador Eliseu Guilherme, 315</p>
                <p>Paraíso, São Paulo/SP</p>
                <p>04004-030</p>
            </div>
        </div>
    </section>
}


export default Map;