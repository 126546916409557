//Marcenaria
import marcenaria1 from '../../assets/works/marcenaria/1.jpeg'
import marcenaria2 from '../../assets/works/marcenaria/2.jpeg'
import marcenaria3 from '../../assets/works/marcenaria/3.jpeg'
import marcenaria4 from '../../assets/works/marcenaria/4.jpeg'
import marcenaria5 from '../../assets/works/marcenaria/5.jpeg'
import marcenaria6 from '../../assets/works/marcenaria/6.jpeg'
import marcenaria7 from '../../assets/works/marcenaria/7.jpeg'
import marcenaria8 from '../../assets/works/marcenaria/8.jpeg'

//Serralheria
import serralheria1 from '../../assets/works/serralheria/1.jpeg'
// import serralheria2 from '../../assets/works/serralheria/2.jpeg'
import serralheria3 from '../../assets/works/serralheria/3.jpeg'
import serralheria4 from '../../assets/works/serralheria/4.jpeg'
import serralheria5 from '../../assets/works/serralheria/5.jpg'
import serralheria6 from '../../assets/works/serralheria/6.jpg'
import serralheria7 from '../../assets/works/serralheria/7.jpg'
import serralheria8 from '../../assets/works/serralheria/8.jpg'
import serralheria9 from '../../assets/works/serralheria/9.jpg'

//Vidraçaria
import vidracaria1 from '../../assets/works/vidracaria/1.jpeg'
import vidracaria2 from '../../assets/works/vidracaria/2.jpeg'
import vidracaria3 from '../../assets/works/vidracaria/3.jpeg'
import vidracaria4 from '../../assets/works/vidracaria/4.jpeg'
import vidracaria5 from '../../assets/works/vidracaria/5.jpeg'
import vidracaria6 from '../../assets/works/vidracaria/6.jpeg'
import vidracaria7 from '../../assets/works/vidracaria/7.jpeg'
import vidracaria8 from '../../assets/works/vidracaria/8.jpeg'
import vidracaria9 from '../../assets/works/vidracaria/9.jpg'

//Chaveiro
import chaveiro1 from '../../assets/works/chaveiro/1.jpeg'
import chaveiro2 from '../../assets/works/chaveiro/2.jpeg'
import chaveiro3 from '../../assets/works/chaveiro/3.jpeg'
import chaveiro4 from '../../assets/works/chaveiro/4.jpeg'
// import chaveiro5 from '../../assets/works/chaveiro/5.jpeg'
// import chaveiro6 from '../../assets/works/chaveiro/6.jpeg'

//Pintura
import pintura1 from '../../assets/works/pintura/1.jpeg'
import pintura2 from '../../assets/works/pintura/2.jpeg'
import pintura3 from '../../assets/works/pintura/3.jpeg'
import pintura4 from '../../assets/works/pintura/4.jpeg'
import pintura5 from '../../assets/works/pintura/5.jpeg'
import pintura6 from '../../assets/works/pintura/6.jpg'
import pintura7 from '../../assets/works/pintura/7.jpg'
import pintura8 from '../../assets/works/pintura/8.jpg'

//Higienização
import higienizacao1 from '../../assets/works/higienizacao/higienizacao1.jpg'
import higienizacao2 from '../../assets/works/higienizacao/higienizacao2.jpg'
import higienizacao3 from '../../assets/works/higienizacao/higienizacao3.jpg'
import higienizacao4 from '../../assets/works/higienizacao/higienizacao4.jpg'
import higienizacao5 from '../../assets/works/higienizacao/higienizacao5.jpg'
import higienizacao6 from '../../assets/works/higienizacao/6.jpg'
import higienizacao7 from '../../assets/works/higienizacao/7.jpg'
import higienizacao8 from '../../assets/works/higienizacao/8.jpg'


export interface CarouselItem {
    title: string;
    subtitle?: string;
    backgroundImage?: string;
    images?: string[];
  }

  export const carouselData: CarouselItem[] = [
      {
        title: "Nossos projetos",
        subtitle: "VEJA OS",
      },
      {
        title: 'Marcenaria',
          images: [
            marcenaria1,
            marcenaria2,
            marcenaria3,
            marcenaria4,
            marcenaria5,
            marcenaria6,
            marcenaria7,
            marcenaria8
          ]
            ,
            backgroundImage: marcenaria5,
      },{
          title: 'Serralheria',
          images: [
            serralheria1,
            serralheria4,
            serralheria5,
            serralheria7,
            serralheria8,
            serralheria9,
            serralheria6,

            serralheria3,

          ],
            backgroundImage: serralheria1,
        },{
          title: 'Vidraçaria',
          images: [
            vidracaria1,
            vidracaria2,
            vidracaria3,
            vidracaria4,
            vidracaria5,
            vidracaria6,
            vidracaria7,
            vidracaria8,
          ]
            ,
            backgroundImage: vidracaria9,
        },
        {
          title: 'Chaveiro',
          images: [
            chaveiro1,
            chaveiro2,
            chaveiro3,
            chaveiro4,
            // chaveiro5,
            // chaveiro6,
          ]
            ,
            backgroundImage: chaveiro1,
        },
        {
          title: 'Pintura',
          images: [
            pintura1,
            pintura6,
            pintura8,
            pintura4,
            pintura5,
            pintura7,
            pintura2,
            pintura3,

          ]
            ,
            backgroundImage: pintura1,
        },
        {
          title: 'Higienização',
          images: [
            higienizacao1,
            higienizacao2,
            higienizacao3,
            higienizacao4,
            higienizacao5,
            higienizacao6,
            higienizacao7,
            higienizacao8,
          ]
            ,
            backgroundImage: higienizacao1,
        },
  ];