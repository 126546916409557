import React from 'react';
import './card.css'
import { CarouselItem } from './carouselData';

interface CardProps extends CarouselItem {
  onClickVerImagens?: () => void;
  showVerImagensButton?: boolean;
}

const Card: React.FC<CardProps> = ({ title, subtitle, backgroundImage, onClickVerImagens, showVerImagensButton  }:any) => {
  return (
    <div className='card'>
      <div className={`card-content ${subtitle ? 'first-card':''}`}>
        {subtitle && <h3>{subtitle}</h3> }

        <h2>{title}</h2>
        {showVerImagensButton && <button className="w-auto bg-white p-2 rounded-lg text-center text-black font-bold hover:bg-yellow-800" onClick={onClickVerImagens}>Ver imagens</button> }
      </div>
      {backgroundImage && <img src={backgroundImage} alt="Imagem de fundo" className="card-image" />}
    </div>
  );
}

export default Card;