import {
    IconButton,
    SpeedDial,
    SpeedDialHandler,
    SpeedDialContent,
    SpeedDialAction,
  } from "@material-tailwind/react";
  import {
    PlusIcon,
  } from "@heroicons/react/24/outline";
import mobile from 'is-mobile';
import { FaInstagram, FaWhatsapp } from "react-icons/fa";
import { AttentionSeeker } from "react-awesome-reveal";


  export function DefaultSpeedDial() {
    return (
        <div className="fixed bottom-5 right-5 z-40 h-auto w-auto">
          <div className="absolute bottom-0 right-0">
            <SpeedDial>
                <AttentionSeeker effect="shakeY">
                  <SpeedDialHandler className="bg-yellow-700 text-black">
                    <IconButton size="lg" className="rounded-full">
                      <PlusIcon className={`h-5 w-5 transition-transform ${!mobile() && 'group-hover:rotate-45'}`} />
                      </IconButton>
                  </SpeedDialHandler>
                  </AttentionSeeker>
              <SpeedDialContent>
                <SpeedDialAction>
                  <FaInstagram className="h-5 w-5" />
                </SpeedDialAction>
                <a href='https://api.whatsapp.com/send/?phone=5511992536640&text=Olá,%20gostaria%20de%20fazer%20um%20orçamento%20com%20vocês&type=phone_number&app_absent=0' target="_blank" rel="noreferrer">
                  <SpeedDialAction>
                    <FaWhatsapp className="h-5 w-5" />
                  </SpeedDialAction>
                </a>
              </SpeedDialContent>
            </SpeedDial>
          </div>
        </div>
    );
  }