import React from 'react';
import Template from '../components/template';
import CarouselNavigation from '../components/carousel';
import Contract from '../components/contract';
import Whatsapp from '../components/whatsapp';
import Services from '../components/services';
import Gallery from '../components/gallery';
import AboutUs from '../components/about-us';
import Map from '../components/map';

const LandingPage: React.FC = () => {
	//https://api.whatsapp.com/send?phone=5511984034984&text=Ol%C3%A1,%20sou%20o%20Carlos%20e%20gostaria%20de%20receber%20um%20or%C3%A7amento%20de%20paisagismo%0AMeu%20e-mail%20%C3%A9%20Tal%20tal%0A%0AAtenciosamente

	return (
		<Template>
        	<CarouselNavigation/>
			<AboutUs/>
			<Services/>
			<Gallery/>
			<Whatsapp/>
			<Contract/>
			<Map/>
		</Template>)
}
export default LandingPage;
