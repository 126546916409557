import React from 'react';
import './styles.css';
import { Typography } from '@material-tailwind/react';
import { Fade } from 'react-awesome-reveal';

const AboutUs: React.FC = () => {
    return <section className='introduction'>
        <div id='introduction'>
            <div className='container'>
                <Fade triggerOnce>
                    <Typography
                        variant="h1"
                        className="text-3xl md:text-4xl lg:text-5xl ">
                        Porque escolher a Hurba Engenharia?
                    </Typography>
                </Fade>

                <p>
                    Desde 2007, a Hurba Engenharia atua em manutenção, obras e
                    reformas prediais, especializando-se em shoppings, condomínios,
                    comércios e residências.
                </p>

                <p>
                    Nosso diferencial está no atendimento. Somos rápidos,
                    eficientes e sempre prestativos. A satisfação do cliente
                    é nossa prioridade.
                </p>

                <p>
                    Se busca qualidade e comprometimento, conte com a Hurba
                    Engenharia. Transformamos espaços, construímos confiança e
                    buscamos constantemente a excelência em manutenção e construção predial.
                </p>
            </div>
        </div>
    </section>
}


export default AboutUs;