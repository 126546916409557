import { Button, Typography } from '@material-tailwind/react';
// import { GrLinkNext } from 'react-icons/gr'
import './styles.css'
import { Fade } from 'react-awesome-reveal';

const Contract: React.FC = () => {
    return <div id='contract' className='contract-container relative'>
            <Fade triggerOnce>
                <section className="bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern-dark.svg')]">
                {/* <section className="bg-white dark:bg-gray-900 bg-[url('https://flowbite.s3.amazonaws.com/docs/jumbotron/hero-pattern.svg')] dark:bg-black"> */}
                    <div className="py-24 px-4 mx-auto max-w-screen-xl text-center lg:py-16 z-10 relative">
                        <a href="mailto: hurba@hurbasolution.com.br" className="inline-flex justify-between items-center py-1 px-1 pr-4 mb-7 text-sm  rounded-full bg-gray-900 text-gray-300 hover:bg-gray-800">
                            <span className="text-xs bg-gray-600 rounded-full text-white px-4 py-1.5 mr-3">Novas Vagas</span> <span className="text-sm text-white font-medium">Junte-se a nós.</span>
                            <svg className="w-2.5 h-2.5 ml-2 text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                            </svg>
                        </a>
                        <Typography
                            variant="h1"
                            className="mb-4 text-3xl md:text-4xl lg:text-5xl text-gray-900 text-white">
                                Faça parte da nossa equipe!
                        </Typography>
                        <Typography
                        variant="lead"
                        color="white"
                        className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 lg:px-48 text-gray-200">
                            Aqui na Hurba, trabalhamos juntos para concretizar projetos que não apenas transformam espaços, mas também impactam vidas. Se você compartilha desse desejo e quer fazer a diferença, está no lugar certo!
                        </Typography>
                        <form className="w-full max-w-md mx-auto">
                            <a href = "mailto: hurba@hurbasolution.com.br">
                            <Button className='inline-flex items-center justify-center bg-yellow-800 hover:bg-yellow-700 text-md font-normal text-black lg:text-lg'>
                                Enviar currículo
                                {/* <GrLinkNext className="w-4 h-4 ml-2"/> */}
                            </Button>
                            </a>
                        </form>
                    </div>
                    <div className="bg-gradient-to-b to-transparent from-purple-900 w-full h-full absolute top-0 left-0 z-0"></div>
                </section>
            </Fade>
        </div>
}

export default Contract;