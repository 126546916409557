import { ChangeEvent, FormEvent, useState } from 'react';
import './styles.css'
import {
    Card,
    Input,
    Checkbox,
    Button,
    Typography,
    Textarea,
  } from "@material-tailwind/react";
import { Bounce, Fade } from 'react-awesome-reveal';

const initialState = {
    name            : null,
    mail            : null,
    city            : null,
    message         : null,
    service         : null
};

const WhatsApp: React.FC = () => {
    const [values, setValues] = useState(initialState);

    function onChange(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) {
        const {name, value} = event.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    function afterSubmission(event:any) {
        event.preventDefault();

        let url = `https://api.whatsapp.com/send/?phone=5511992536640&text=Ol%C3%A1,%20gostaria%20de%20receber%20mais%20informa%C3%A7%C3%B5es%20sobre%20os%20servi%C3%A7os%20dispon%C3%ADveis:%0A${(values.name && "Nome:%20"+values.name + "%0A")}${(values.mail && "E-mail:%20" + values.mail+ "%0A")}${(values.city && "Localiza%C3%A7%C3%A3o:%20" + values.city+ "%0A")}${(values.message && "Tipo%20de%20servi%C3%A7o:%20" + values.message)}`
        url = url.replaceAll("null","");
        url = url.replaceAll("nullnull","");
        window.open(url)
    }

    return <Card color="transparent" shadow={false} className='wpp-container flex flex-col justify-center lg:flex-row gap-6' id="contact">
            <div className='flex flex-col content-center justify-center gap-10'>
                <Fade triggerOnce className='w-full'>
                    <Typography
                        variant="h1"
                        className="text-3xl md:text-4xl lg:text-5xl ">
                            Receba seu orçamento personalizado!
                    </Typography>
                </Fade>
                <Typography
                    variant="paragraph"
                    className="text-black-900 text-2xl">
                        Preencha o formulário e fale com um de nossos especialistas pelo WhatsApp agora mesmo.
                </Typography>

            </div>
            <form className="mt-8 mb-2" onSubmit = {afterSubmission} >
                <div className="mb-4 flex flex-col gap-6">
                    <Input size="md" label="Nome" type='text' crossOrigin={undefined} name='name'onChange={onChange} minLength={3} required/>
                    <Input size="md" label="E-mail" type='email' crossOrigin={undefined} name='mail'onChange={onChange} required/>
                    <Input size="md" label="Localização dp serviço" type='text'crossOrigin={undefined} name='city'onChange={onChange} minLength={3}/>
                    <Input size="md" label="Tipo de serviço que deseja" type='text' crossOrigin={undefined} name='service'onChange={onChange} minLength={3}/>
                    <Textarea size="md" label="Mensagem"  name='message'onChange={onChange} minLength={3}/>
                </div>
                <Button className="inline-flex items-center justify-center bg-yellow-800 hover:bg-yellow-700 text-md font-normal text-black" fullWidth type='submit'>
                    Enviar
                </Button>
            </form>
        </Card>
}

export default WhatsApp;