import React, { useState } from "react";
import mobile from 'is-mobile';
import { AiOutlineCheck } from 'react-icons/ai'
import './styles.css'
import icon from '../../assets/logos/logo.svg'
import { Dialog, DialogBody } from "@material-tailwind/react";
import { Fade } from "react-awesome-reveal";

const eletricos = [
     "Correção de curto-circuito",
     "Manutenção preventiva de motores e bombas" ,
     "Instalação, manutenção e troca de fiação elétrica",
     "Instalação, manutenção e troca de Disjuntor",
     "Instalação, manutenção e troca de Quadros elétricos",
     "Instalação, manutenção e troca de Luminárias",
     "Instalação, manutenção e troca de Tomadas e interruptores",
     "Instalação, manutenção e troca de Entradas de energia",
];

const cftv = [
     "Instalação, manutenção e troca de cabeamento de rede de dados" ,
     "Instalação, manutenção e troca de câmeras" ,
     "Instalação, manutenção e troca de DVRs para sistemas de monitoramento" ,
     "Instalação, manutenção e troca de interfones" ,
     "Instalação, manutenção e troca de racks" ,
     "Certificação de pontos CAT com laudo" ,
];

const incendio = [
     "Instalação, manutenção e troca de hidrantes" ,
     "Instalação, manutenção e troca de sistemas de detecção de incêndio" ,
     "Instalação, manutenção e troca de centrais de alarme de incêndio" ,
     "Instalação, manutenção e troca de sprinkler" ,
     "Instalação, manutenção e troca de tubulação de sprinkler" ,
];

const areaCivil = [
     "Execução de pinturas internas e externas" ,
     "Execução de pintura de fachada de edifícios com toda documentação para trabalho em altura e equipamentos homologados" ,
     "Execução de alvenaria e reboco" ,
     "Execução de revestimentos cerâmicos, porcelanatos e pedras" ,
     "Instalação de bancadas em granito" ,
     "Execução de impermeabilização em fachadas e coberturas" ,
     "Instalação, manutenção e troca de telhados" ,
];

const hidraulica = [
     "Instalação, manutenção e reparo hidráulico (incluindo reparo de vazamentos em pias, vasos, canos e torneiras)" ,
     "Instalação, manutenção, troca e limpeza de caixa d’água (com ou sem laudo)" ,
     "Instalação, manutenção, troca de vaso sanitário, descarga (válvula hydra) e ducha higiênica " ,
     "Instalação, limpeza e troca de sifão e torneira" ,
     "Encanamento de água quente e fria" ,
     "Instalação, manutenção e troca de redes pluviais – água da chuva" ,
     "Instalação, manutenção e troca de bueiros, tubulações de drenagem e bombas para reservatórios" ,
];

const portaria = [
     "Controle de acesso" ,
     "Monitoramento de cameras" ,
     "Gerenciamento de Correspondências e Encomendas" ,
     "Atendimento ao Público" ,
];

const chaveiro = [
     "Copias comuns, tetras e veiculos" ,
     "Aberturas de portas, armarios, e veiculos" ,
     "Serviços de Emergência" ,
     "Instalação e Manutenção de Fechaduras" ,
     "Cadeados" ,
];
const serralheria = [
     "Fabricação e reparo em portas e portões" ,
     "Fabricação e reparo em janelas, guarda-corpo" ,
     "Fabricação e reparo em  escada comum, grelhas e ralos lineares" ,
     "Fabricação e reparo em  escada marinheiro" ,
];

const marcenaria = [
     "Reparo mobiliario residiencial e de escritório" ,
     "Confecção de moveis planejados, decks" ,
     "Fabricação e reparo de mobiliario de escritorio" ,
     "Instalação de móveis" ,
];

const vidracaria = [
     "Instalação de Vidros" ,
     "Aplicação de Insulfime espelhado, jateado e escuro" ,
     "Confecção e instalação de box, e guarda corpo" ,
     "Trocas de vidros de janelas e portas" ,
     "Espelhos" ,
];

const higienizacao = [
     "Limpeza especializada em estofados no geral" ,
     "Limpeza de Carpetes",
     "Limpeza de Tapetes",
     "Limpeza de Sofás",
     "Limpeza de Poltronas",
     "Limpeza de Colchões",
];


const Services: React.FC = () => {

    const [data, setData] = useState (eletricos);
    const [open, setOpen] = React.useState(false);

    function handleOpen(value:any){
        value !== false && setData(value)
        mobile() && setOpen(!open)
    }

  return <>
    <div id='services' className="">
        <div className="first-content relative text-white bg-gray-900 ">
            <div className="first relative z-10">

                <p className="w-full text-xl">NOSSOS SERVIÇOS</p>
                <h1 className="w-full text-3xl font-bold mt-3">Veja o que podemos fazer por você hoje:</h1>
                <Fade triggerOnce className="w-full">
                    <div className="list-services rounded-xl mt-10">
                        <button onClick={() => handleOpen(eletricos) }><AiOutlineCheck/>Serviços de elétrica</button>
                        <button onClick={() => handleOpen(marcenaria)}><AiOutlineCheck/>Marcenaria</button>
                        <button onClick={() => handleOpen(hidraulica)}><AiOutlineCheck/>Área hidráulica</button>
                        <button onClick={() => handleOpen(serralheria)}><AiOutlineCheck/>Serralheria</button>
                        <button onClick={() => handleOpen(cftv)}><AiOutlineCheck/>Dados, CFTV, interfone</button>
                        <button onClick={() => handleOpen(portaria)}><AiOutlineCheck/>Portaria</button>
                        <button onClick={() => handleOpen(incendio)}><AiOutlineCheck/>Prevenção de incêndio</button>
                        <button onClick={() => handleOpen(chaveiro)}><AiOutlineCheck/>Chaveiro</button>
                        <button onClick={() => handleOpen(areaCivil)}><AiOutlineCheck/>Área civil</button>
                        <button onClick={() => handleOpen(vidracaria)}><AiOutlineCheck/>Vidraçaria</button>
                        <button onClick={() => handleOpen(higienizacao)}><AiOutlineCheck/>Higienização</button>
                    </div>
                </Fade>
                <Fade triggerOnce cascade className="content-buttons w-full">
                    <a  href="https://api.whatsapp.com/send/?phone=5511992536640&text=Olá,%20gostaria%20de%20conhecer%20seus%20serviços&type=phone_number&app_absent=0"
                        target="_blank"
                        className="w-full text-lg bg-white p-4 mt-8 rounded-xl text-center text-black font-bold hover:bg-yellow-800"
                        rel="noreferrer">
                            <button>Solicitar orçamento via Whatsapp</button>
                    </a>
                    <a href="mailto: comercial@hurbasolution.com.br" className="font-bold text-lg w-full text-center mt-6">
                        <button className='hover:underline'>Solicitar orçamento por email</button>
                    </a>
                </Fade>
                <Dialog  className='p-0' id="modal-services" open={ open } handler={handleOpen} >
                    <DialogBody className='rounded-lg p-0' divider>
                        {data.map((el, id) => <>
                            <div className="modal flex mt-4 flex-start" key={id}>
                                <span className="rounded-lg span-modal"> <img src={icon} alt={'icon'} ></img></span>
                                <p className='text-xl font-semibold pb-4 ml-2 w-4/5' key={el}> {el}</p>
                            </div>
                        </>)}
                    </DialogBody>
                </Dialog>


            </div>
            <div className="bg-gradient-to-b to-transparent from-purple-900 w-full h-full absolute top-0 left-0 z-0"></div>
        </div>
        {!mobile() && <div className="second">
            {data.map((el, id) => <>
                <div className="flex mt-4 flex-start" key={id}>
                    <span className="rounded-lg"> <img src={icon} alt={'icon'} ></img></span>
                    <p className='text-xl font-semibold pb-4 ml-2 w-4/5' key={el}> {el}</p>
                </div>
            </>)}
        </div>
        }
    </div>

    </>
}

export default Services;
