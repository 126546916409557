import React, { useState } from 'react'
import mobile from 'is-mobile';
import { Carousel, Typography } from '@material-tailwind/react'
import { Fade } from 'react-awesome-reveal'
import './styles.css'
import { CarouselItem, carouselData } from './carouselData';
import Card from './card';

interface ImageCarouselProps {
  images: string[];
}

const MultipleCarousels: React.FC = () => {
  const [showCarousel, setShowCarousel] = useState(false);
  const [selectedCarousel, setSelectedCarousel] = useState<CarouselItem | null>(null);

  const handleVerImagensClick = (carousel: CarouselItem) => {
    setSelectedCarousel(carousel);
    setShowCarousel(true);

    // Role a página até o carousel
    const carouselElement = document.getElementById("content-carousel");
    if (carouselElement) {
      carouselElement.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  function ImageCarousel({ images }: ImageCarouselProps) {
    // Divide o array de imagens em grupos de 2 ou 4
    const imageGroups: string[][] = [];
    let qttImgPerCarousel = mobile() ? 2 : 4
    for (let i = 0; i < images.length; i += qttImgPerCarousel) {
      imageGroups.push(images.slice(i, i + qttImgPerCarousel));
    }

    return (
      <Carousel>
        {imageGroups.map((group, groupIndex) => (
          <div key={groupIndex} className="content-images">
            {group.map((image, imageIndex) => (
              <div key={imageIndex} className='cursor-pointer overflow-hidden transition-opacity hover:opacity-90 rounded-lg'>
                <Fade className='h-full'>
                  <img src={image} alt={`Image ${groupIndex * qttImgPerCarousel + imageIndex + 1}`} className='h-full w-full object-cover object-center' />
                </Fade>
              </div>
            ))}
          </div>
        ))}
      </Carousel>
    );
  }

  return (
    <div id='content-gallery'>
      <div id="cards-container">
        {carouselData.map((card, index) => (
          <Card
            key={index}
            {...card}
            onClickVerImagens={() => handleVerImagensClick(card)}
            showVerImagensButton={index === 0 ? false : true} // Mostra o botão "Ver imagens" apenas no primeiro card
          />
        ))}
      </div>

      <div id="content-carousel" >
      {showCarousel && selectedCarousel && (<>
        <hr className='xl:mt-8 lg:mt-4'/>
          <Typography
                variant="h1"
                className="text-3xl md:text-4xl lg:text-5xl mt-24 mb-4">
                Projetos de  {selectedCarousel.title}:
            </Typography>
          <ImageCarousel images={selectedCarousel.images || []} />
          </>
          )}
          </div>

      <hr className='xl:mt-16 lg:mt-8'/>
    </div>
  );
}

export default MultipleCarousels;